.App {
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

button {
  margin: 5px;
}

.bar {
  width: 50%;
  /* color:white; */
}

:root {
  --md-linear-progress-track-height: 20px;
  --md-linear-progress-track-shape: 8px;
  --md-linear-progress-active-indicator-height: 20px;
  --md-sys-color-primary: #006A6A;
  --md-sys-color-surface-container-highest: #DDE4E3;

  --md-filled-tonal-button-container-shape: 5px;
  --md-filled-tonal-button-label-text-font: system-ui;
  --md-sys-color-secondary-container: #CCE8E7;
  --md-sys-color-on-secondary-container: #051F1F;
}

/* .md-bar {
  width: 500px;
} */

md-linear-progress {
  width: 30vw;
}

md-filled-tonal-button {
  margin: 5px;
}